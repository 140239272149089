
import { Options, Vue } from "vue-class-component";
import router from "@/router";

@Options({
  mounted() {
    router.push("/game");
  },
})
export default class GameRedirect extends Vue {}
